<template>
  <el-dialog
    :title="translateTitle('没有收到验证码？')"
    width="426px"
    v-bind="$attrs"
    center
    class="not-code-dialog"
    :show-close="false"
    top="0"
    append-to-body
  >
    <div class="text-container" v-if="!isMobile">
      <div class="text">{{ translateTitle("真遗憾。不妨试试以下步骤?") }}</div>
      <div class="h-8"></div>
      <div class="text">
        {{ translateTitle("1、请确保您输入的邮箱正确无误。") }}
      </div>
      <div class="text">
        {{ translateTitle("2、请检查您的垃圾邮箱,您可能会找到想的邮件。") }}
      </div>
      <div class="text">
        {{ translateTitle("3、请稍等片刻,可能会有延迟。") }}
      </div>
      <div class="h-8"></div>
      <div class="text">
        {{ translateTitle("如果您始终没收到验证码,请联系我们的客服。") }}
      </div>
    </div>
    <div class="text-container" v-else>
      <div class="text">
        {{ translateTitle("真遗憾。不妨试试以下步骤?") }}
      </div>
      <div class="h-8"></div>
      <div class="class">
        {{ translateTitle("1、请确保您输入的手机号正确无误。") }}
      </div>
      <div class="class">
        {{ translateTitle("2、请稍等片刻,可能会有延迟。") }}
      </div>
      <div class="h-8"></div>
      <div class="class">
        {{ translateTitle("如果您始终没收到验证码,请联系我们的客服。") }}
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="ok-btn" type="primary" @click="$emit('input', false)">{{
        translateTitle("知道了")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.not-code-dialog {
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep {
    .el-dialog {
      border-radius: 12px;
      margin-bottom: 0 !important;
    }
    .el-dialog__header {
      padding: 33px 0 0;

      .el-dialog__title {
        color: var(--7-c_t01, #0d0e0e);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 90% */
      }
    }
    .el-dialog--center .el-dialog__body {
      padding-left: 56px;
      padding-right: 56px;
      padding-top: 18px;
      padding-block: 24px;
    }
    .el-dialog__footer {
      padding-left: 56px;
      padding-right: 56px;
    }
  }

  .ok-btn {
    border-radius: 6px;
    background: #3449f5;
    height: 48px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    border-color: #3449f5;
  }
}
.text-container {
  color: #5c5f69;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.5px;
}

.h-8 {
  height: 8px;
}
</style>
